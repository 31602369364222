.footer-body{
        background-color: #111;
        margin-left: -6vw;
        margin-right: -6vw;
        color: #fff;
       
        h1{
                text-align: center;
                font-size: 2rem;
                color: #00c274;
        }

        p{
                padding: 0.8vw;
                font-size: 1.4rem;
                margin: 15px 11vw;        }
  
        .address{
                margin-top: 42px;
                text-align: center;
                border-top: solid #DBDBDB;
                color: #00c274;
                font-size: 0.9em;
                word-spacing: 20px; 
                
                a{
                        color: #DBDBDB;
                        text-decoration: none;
                        font-size: 0.9em;
                        transition: 0.5s;
                        &:hover{
                                color: #00c274;
                            }
                }
        }
}

