.home-body{
    margin-top: 15px;
    background-color: #F9F9F9;
    font-size: 1.5em;
    font-family: 'Nunito Sans', sans-serif;
}

.first-txt{
    margin: 6vw;
    margin-bottom: 0;
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    
    h1{
        font-family: 'Roboto Mono', monospace;
        text-align: center;
        font-size: 1.8em;
        margin-bottom: 15px;
    }

    .square{
        display: grid;
        grid-template-columns: 1fr;
        padding: 50px;
        background-color: #1C1C1C;
        margin-left: -6vw;
        margin-right: -6vw;
        color: #fff;

        b{
            color: #00c274;
        }

        .top{
            margin: 15px 50px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            
            .isa{
                padding: 10px;
            
            }
            a{
                text-decoration: none;
                color: #fff;

                &:hover{
                    color: #00c274;
                }
            }
        }
        .mid{
            margin: 15px 50px;
            
        }
        .bot{
            margin: 30px 50px;
            display: grid;
            grid-template-columns: 1fr 1fr;

            .bot2{
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }
}

.second-txt{
    margin: 0px 6vw;
    justify-content: space-between;
    background-color: #F9F9F9;


    h2{
        font-family: 'Roboto Mono', monospace;
        font-size: 1.1em;
    }
    .income{
        padding: 15px;
    }
    .mid-bg{
            //content: "";
            p{ 
                 color: black; 
            }
    }

    .mid-ol{  
        color: #F9F9F9;
        background-image: url(../../static/assets/images/demo0.jpg);
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: -6vw;
        margin-right: -6vw;
        display: grid;
        grid-template-columns: 1fr 1fr;

        li{
            margin-left: 150px;
            object-fit: cover;
            line-height: 5.1vh;
            font-size: 1.4rem;  
            margin: 0px 6vw;
            //font-weight: bolder;
        }
    }
}

.thirt-txt{
    margin: 0vw 6vw ;

    b{
        color: #00c274;
    }
    h2{
        font-family: 'Roboto Mono', monospace;
        font-size: 0.99em;
    }
    .chash-flow-list{
        
        margin-left: 3vw;
        line-height: 5.5vh;
        font-size: 1.4rem;

    }
    .sales-isa-list{
        margin-left: 3vw;
        line-height: 5.8vh;
        font-size: 1.4rem;
    }
}

.more-info{
    margin: 5vw;
    display: flex;
    flex-direction: row;
   
    .formore-info{
        font-family: 'Roboto Mono', monospace;
        font-size: .8em;
        margin: 1vh;
    }

    .contact-info{
        margin-top: 0;
        margin-left: 20px;
        font-size: 1.4rem;

    }
}