.auth-page-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;


    .left-column{

        background-image: url(../../static/assets/images/half-girl.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .right-column{
        .main-logo img{
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        .login-to-access{
            display: grid;
            grid-template-columns: 1fr;
            justify-content: center;
            align-content: center;
            text-align: center;
            align-items: center;
            background-color: #f6f6f6;

            input {
              //display: block;
              border: 1px solid #ccc;
              border-radius: 5px;
              background: #fff;
              padding: 15px;
              margin: 15px;
              outline: none;
              transition: 0.3s;
              width: 66%;
            }

            #submit{
              width: 50%
            }
        }
    }
}
/* else*/

  
  .main-div, .loggedin-div {
    width: 20%;
    margin: 0px auto;
    margin-top: 150px;
    padding: 20px;
    display: none;
  }
  
  .main-div input {
    display: block;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    padding: 15px;
    outline: none;
    width: 100%;
    margin-bottom: 20px;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
  }
  
  .main-div input:focus {
    border: 1px solid #777;
  }
  
  .main-div button, .loggedin-div button {
    background: #5d8ffc;
    color: #fff;
    border: 1px solid #5d8ffc;
    border-radius: 5px;
    padding: 15px;
    display: block;
    width: 100%;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
  }
  
  .main-div button:hover, .loggedin-div button:hover {
    background: #fff;
    color: #5d8ffc;
    border: 1px solid #5d8ffc;
    cursor: pointer;
  }
  
