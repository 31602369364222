.header-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  align-content: center;
  height: 340px;
  background-color: #FCFCFC;
  font-family: 'Roboto Mono', monospace;
  font-size: 2rem;
  font-weight: 580;
  box-shadow: 10px 38px 65px -11px rgba(0, 0, 0, 0.09);
  margin-bottom: 15px;
  margin-top: -25px;
  background-image: url(../../static/assets/images/top4.jpg);
  background-position: center;
  background-position-y: 29%;
  background-repeat: no-repeat;
  background-size: cover;
}

.header-body .first-logo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.header-body .first-logo h1 {
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  font-size: 0.99rem;
  margin-left: 65px;
  margin-top: -20px;
  color: #ddd;
}

.header-body .first-logo img {
  height: 170px;
  width: 250px;
}

.header-body .bottega-logo img {
  height: 90px;
  margin-bottom: -15px;
  margin-left: 10%;
}

.typewriter h1 {
  color: #DBDBDB;
  font-size: 2rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  border-right: .15em solid black;
  white-space: nowrap;
  margin-right: 3% auto;
  letter-spacing: .10em;
  animation: typing 3.5s steps(25, end), blink-caret 0.5s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.footer-body {
  background-color: #111;
  margin-left: -6vw;
  margin-right: -6vw;
  color: #fff;
}

.footer-body h1 {
  text-align: center;
  font-size: 2rem;
  color: #00c274;
}

.footer-body p {
  padding: 0.8vw;
  font-size: 1.4rem;
  margin: 15px 11vw;
}

.footer-body .address {
  margin-top: 42px;
  text-align: center;
  border-top: solid #DBDBDB;
  color: #00c274;
  font-size: 0.9em;
  word-spacing: 20px;
}

.footer-body .address a {
  color: #DBDBDB;
  text-decoration: none;
  font-size: 0.9em;
  transition: 0.5s;
}

.footer-body .address a:hover {
  color: #00c274;
}

.home-body {
  margin-top: 15px;
  background-color: #F9F9F9;
  font-size: 1.5em;
  font-family: 'Nunito Sans', sans-serif;
}

.first-txt {
  margin: 6vw;
  margin-bottom: 0;
  margin-top: 2vh;
  display: flex;
  flex-direction: column;
}

.first-txt h1 {
  font-family: 'Roboto Mono', monospace;
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 15px;
}

.first-txt .square {
  display: grid;
  grid-template-columns: 1fr;
  padding: 50px;
  background-color: #1C1C1C;
  margin-left: -6vw;
  margin-right: -6vw;
  color: #fff;
}

.first-txt .square b {
  color: #00c274;
}

.first-txt .square .top {
  margin: 15px 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.first-txt .square .top .isa {
  padding: 10px;
}

.first-txt .square .top a {
  text-decoration: none;
  color: #fff;
}

.first-txt .square .top a:hover {
  color: #00c274;
}

.first-txt .square .mid {
  margin: 15px 50px;
}

.first-txt .square .bot {
  margin: 30px 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.first-txt .square .bot .bot2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.second-txt {
  margin: 0px 6vw;
  justify-content: space-between;
  background-color: #F9F9F9;
}

.second-txt h2 {
  font-family: 'Roboto Mono', monospace;
  font-size: 1.1em;
}

.second-txt .income {
  padding: 15px;
}

.second-txt .mid-bg p {
  color: black;
}

.second-txt .mid-ol {
  color: #F9F9F9;
  background-image: url(../../static/assets/images/demo0.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: -6vw;
  margin-right: -6vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.second-txt .mid-ol li {
  margin-left: 150px;
  object-fit: cover;
  line-height: 5.1vh;
  font-size: 1.4rem;
  margin: 0px 6vw;
}

.thirt-txt {
  margin: 0vw 6vw;
}

.thirt-txt b {
  color: #00c274;
}

.thirt-txt h2 {
  font-family: 'Roboto Mono', monospace;
  font-size: 0.99em;
}

.thirt-txt .chash-flow-list {
  margin-left: 3vw;
  line-height: 5.5vh;
  font-size: 1.4rem;
}

.thirt-txt .sales-isa-list {
  margin-left: 3vw;
  line-height: 5.8vh;
  font-size: 1.4rem;
}

.more-info {
  margin: 5vw;
  display: flex;
  flex-direction: row;
}

.more-info .formore-info {
  font-family: 'Roboto Mono', monospace;
  font-size: .8em;
  margin: 1vh;
}

.more-info .contact-info {
  margin-top: 0;
  margin-left: 20px;
  font-size: 1.4rem;
}

@media (max-width: 1100px) {
  body {
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #ddd;
    overflow-x: hidden;
  }
  .header-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 400px;
    background-image: url(../../static/assets/images/topbg2.jpg);
  }
  .header-body .first-logo {
    order: 1;
    margin-top: 10px;
    filter: opacity(200%);
  }
  .header-body .first-logo h1 {
    order: 2;
  }
  .header-body .bottega-logo {
    order: 3;
    margin-top: 10px;
    margin-left: 0%;
  }
  .header-body .typewriter h1 {
    color: #fff;
    filter: brightness(100%);
    order: 4;
    margin-top: 10px;
    font-size: 1.2rem;
    margin-left: 8%;
    width: 400px;
  }
  .home-body .first-txt h1 {
    font-size: 35px;
    margin-top: -10px;
  }
  .home-body .first-txt p {
    font-size: 20px;
    margin-top: -5px;
  }
  .home-body .first-txt .square {
    margin-right: none;
  }
  .home-body .first-txt .square .top {
    margin-top: -25px;
    margin-left: -15px;
    font-size: .8rem;
  }
  .home-body .first-txt .square .mid {
    margin-left: -10px;
    font-size: 1rem;
  }
  .home-body .first-txt .square .bot {
    margin-left: -15px;
    font-size: .8rem;
  }
  .home-body .second-txt h2 {
    font-size: 21px;
    margin-bottom: 30px;
  }
  .home-body .second-txt p {
    font-size: 17px;
    color: #1C1C1C;
  }
  .home-body .second-txt .income {
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 10px;
    box-shadow: none;
    border-bottom: solid #00c274;
    border-radius: 10px;
  }
  .home-body .second-txt ol {
    margin-left: 5px;
    background-color: #1C1C1C;
  }
  .home-body .second-txt ol li {
    padding: 5px;
    color: #f3f3f3;
    object-fit: cover;
    line-height: 22px;
    font-size: 0.9rem;
    margin: 0px 6vw;
  }
  .home-body .second-txt .mid-ol {
    background-image: none;
    grid-template-columns: 1fr;
  }
  .home-body .thirt-txt h2 {
    font-size: 23px;
  }
  .home-body .thirt-txt p {
    font-size: 17px;
  }
  .home-body .thirt-txt .chash-flow-list {
    margin-left: -5px;
    font-size: 15px;
  }
  .home-body .thirt-txt .sales-isa-list {
    margin-left: -5px;
    font-size: 15px;
  }
  .home-body .more-info {
    display: grid;
    grid-template-columns: 1fr;
  }
  .home-body .more-info .formore-info {
    font-size: 17px;
  }
  .home-body .more-info .contact-info {
    font-size: 14px;
  }
  .footer-body h1 {
    font-size: .99em;
    padding: 15px;
  }
  .footer-body P {
    font-size: .9rem;
  }
  .footer-body .address {
    font-size: 0.3em;
    word-spacing: 9px;
    padding: 20px;
    border-top: solid #00c274;
    letter-spacing: 0;
  }
  .footer-body .address:hover {
    color: #fff;
    background-color: #207b88;
  }
  .auth-page-wrapper .left-column {
    background-image: none;
  }
  .auth-page-wrapper .right-column {
    width: 100%;
    height: 100%;
  }
  .auth-page-wrapper .right-column .main-logo img {
    width: 250px;
    height: 125px;
  }
}

@media (min-width: 2100px) {
  .isa1 {
    padding: 10px;
    text-align: center;
  }
  .mid {
    text-align: center;
  }
  .bot1 {
    text-align: center;
  }
}

.auth-page-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
}

.auth-page-wrapper .left-column {
  background-image: url(../../static/assets/images/half-girl.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.auth-page-wrapper .right-column .main-logo img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.auth-page-wrapper .right-column .login-to-access {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: center;
  text-align: center;
  align-items: center;
  background-color: #f6f6f6;
}

.auth-page-wrapper .right-column .login-to-access input {
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  padding: 15px;
  margin: 15px;
  outline: none;
  transition: 0.3s;
  width: 66%;
}

.auth-page-wrapper .right-column .login-to-access #submit {
  width: 50%;
}

/* else*/
.main-div, .loggedin-div {
  width: 20%;
  margin: 0px auto;
  margin-top: 150px;
  padding: 20px;
  display: none;
}

.main-div input {
  display: block;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  padding: 15px;
  outline: none;
  width: 100%;
  margin-bottom: 20px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}

.main-div input:focus {
  border: 1px solid #777;
}

.main-div button, .loggedin-div button {
  background: #5d8ffc;
  color: #fff;
  border: 1px solid #5d8ffc;
  border-radius: 5px;
  padding: 15px;
  display: block;
  width: 100%;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}

.main-div button:hover, .loggedin-div button:hover {
  background: #fff;
  color: #5d8ffc;
  border: 1px solid #5d8ffc;
  cursor: pointer;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #f6f6f6;
  align-items: center;
  align-self: auto;
  text-align: justify;
}
