@media (max-width: 1100px) {
    body{
        justify-content: center;
        align-content: center;
        align-items: center;
        background-color: #ddd;
        overflow-x: hidden;
    }

    .header-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 400px;
        background-image: url(../../static/assets/images/topbg2.jpg);

        .first-logo{
            order: 1;
            margin-top: 10px;
            filter: opacity(200%);

            h1{
            order: 2;
        
            }
        }
        .bottega-logo{
            order: 3;
            margin-top: 10px;
            margin-left: 0%;
        }
        .typewriter h1{
            color: #fff;
            filter: brightness(100%);
            order: 4;
            margin-top: 10px;
            font-size: 1.2rem;
            margin-left: 8%;
            width: 400px;
        }
    }

    .home-body{
        .first-txt{
            h1{
                font-size: 35px;
                margin-top: -10px;
            }
            p{
                font-size: 20px;
                margin-top: -5px;
            }

            .square{
                margin-right: none;

                .top{
                    margin-top: -25px;
                    margin-left: -15px;
                    font-size: .8rem;
                }
                .mid{
                    margin-left: -10px;
                    font-size: 1rem; 
                }

                .bot{
                    margin-left: -15px;
                    font-size: .8rem;
                }
            }
        }

        .second-txt{
            h2{
                font-size: 21px;
                margin-bottom: 30px;
            }
            p{
                font-size: 17px;
                color: #1C1C1C;
            }
            .income{
                margin-top: 5px;
                margin-bottom: 10px;
                padding: 10px;
                box-shadow: none;
                border-bottom: solid #00c274;
                border-radius: 10px;
            }
            ol{  
                margin-left: 5px;
                background-color: #1C1C1C;
         
                 li{
                     padding: 5px;
                     color: #f3f3f3;
                     object-fit: cover;
                     line-height: 22px;;
                     font-size: 0.9rem;  
                     margin: 0px 6vw;
         
                 }
              
             }

             .mid-ol{
                 background-image: none;
                 grid-template-columns: 1fr;
             }
        
        }

        .thirt-txt{
            h2{
                font-size: 23px;
            }
            p{
                font-size: 17px;
            }
            .chash-flow-list{
                margin-left: -5px;
                font-size: 15px;
            }
            .sales-isa-list{
                margin-left: -5px;
                font-size: 15px;
            }
        }

        .more-info{
            display: grid;
            grid-template-columns: 1fr;

            .formore-info{
                font-size: 17px;
            }
            .contact-info{
                font-size: 14px;
            }
        }
    }

    .footer-body{

        h1{
            font-size: .99em;
            padding: 15px;
        }

        P{
            font-size: .9rem;
        }

        .address{
            font-size: 0.3em;
            word-spacing: 9px; 
            padding: 20px;
            border-top: solid #00c274;
            letter-spacing: 0;

            &:hover{
                color: #fff;
                background-color: #207b88;
            }
        }
    }

    .auth-page-wrapper {
        .left-column{
            background-image: none;
            }
        .right-column{
            width: 100%;
            height: 100%;
            .main-logo img{
                width: 250px;
                height: 125px;
            }
        }
    }
}

@media (min-width: 2100px) {
    .isa1{
        padding: 10px;
        text-align: center;
    }

    .mid{
        text-align: center;
    }

    .bot1{
        text-align: center;

    }
}